import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

class MobileMenuDriver extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  render() {
    const { collapsed } = this.state;
    return (
      // collapsible collapsed={collapsed} onCollapse={this.onCollapse}
      <div className="mobile-navbar">
        <ul>
          <li>
            <NavLink to="/trips/" exact>
              <span className="icons icon-assign-vehicle"></span>
              <span className="menu-text">New Trips</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/" exact>
              <span className="icons icon-mobile-home"></span>
              <span className="menu-text">Home</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/completed-trips/" exact>
              <span className="icons icon-assign-vehicle-new"></span>
              <span className="menu-text">Completed Trips</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
export default MobileMenuDriver;
