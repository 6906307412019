import { Row, Col, Button } from "antd";
import React from "react";
import "./stockCard.scss";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
const TaskCard = (pros) => {
  function acceptOrder(value) {
    console.log("welcome", value);
    axios
      .get(`${getServerUrl()}/vendor/updatestockstatus/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          window.location.reload();
        }
      });
  }
  return (
    <div className="taskCard">
      <div className="carddetail">
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Component No & Name: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.component_no} - {pros.component_name}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Process: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.process_name}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Process Desc: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.processdesc}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Raw Material & Variant </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.raw_material_name} - {pros.rawvariant}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Raw Material Batch </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.stid}/{pros.rawbatch}/{pros.order_id}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Component Weight: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.component_weight} {pros.raw_material_type_unit}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Quantity: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.quantity}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <Button
              style={{ background: "#008204", color: "#fff" }}
              onClick={() => acceptOrder(pros.order_id)}
            >
              {" "}
              Accept
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TaskCard;
