import React, { Component, useEffect, useState } from "react";
import { Statistic, Row, Col, Button, Breadcrumb, Select } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TripCard from "../components/tripcard";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const Trips = () => {
  let [trip, settrip] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getdrivertrip/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          settrip(details.data);
        }
      });
  }, []);
  const tripnew = trip.filter((triplist) => Number(triplist.remain) >= 1);
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>New Trips</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <Row style={{ minHeight: 200 }}>
          {tripnew.length > 0 ? (
            tripnew.map((triplist) => (
              <Col xs={24} xl={8}>
                <TripCard
                  tripid={triplist.tripid}
                  trip_date={triplist.trip_date}
                  tasks={triplist.tasks}
                  completed={triplist.completed}
                  remain={triplist.remain}
                  tripstatus={triplist.tripstatus}
                />
              </Col>
            ))
          ) : (
            <h2 style={{ color: "#bcbcbc" }}>No New Trips</h2>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Trips;
