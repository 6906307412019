import React, { useState, useEffect, Component } from "react";
import { Layout, Drawer, Button, Avatar, Spin } from "antd";
import { UserOutlined, RedoOutlined } from "@ant-design/icons";
import { Breadcrumb, Row, Col, Space, Card, DatePicker } from "antd";
import { getServerUrl } from "./config";
import moment from "moment";
import axios from "axios";
import logo from "./sarang.svg";
import SideBar from "./components/sidebar";
import MobileMenu from "./components/mobile-menu";
import MobileMenuDriver from "./components/mobile-menu-driver";
import "./App.scss";
import "./style.css";
import "antd/dist/antd.css";
import Home from "./pages/home";
import InStock from "./pages/in-stock";
import ViewInward from "./pages/viewinward";
import Schedule from "./pages/schedule";
import OutWard from "./pages/outward";
import Trips from "./pages/trips";
import Tasks from "./pages/tasks";
import TripDetail from "./pages/tripdetail";
import Inventory from "./pages/inventory";
import StockDetail from "./pages/stockdetail";
import ReadyStock from "./pages/readystock";
import UpcomingTrips from "./pages/upcomingtrips";
import CompletedTrips from "./pages/complete-trips";
import VendorReport from "./pages/vendor-report";
import DriverReport from "./pages/driver-report";
import { Switch, Route } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Header, Content, Footer } = Layout;

const Dashboard = () => {
  const [loader, setloader] = useState("");
  const dateFormat = "YYYY/MM/DD";
  const [visible, setVisible] = useState(false);
  const [userState, setuserState] = useState("");
  const [userLogin, setuserLogin] = useState(false);
  useEffect(() => {
    setuserState(localStorage.getItem("usertype"));
  }, []);

  useEffect(() => {
    setloader("loader");
    if (!localStorage.getItem("fromDate")) {
      let thisMoment = moment();
      let endOfMonth = moment(thisMoment).endOf("month").format(dateFormat);
      let startOfMonth = moment(thisMoment).startOf("month").format(dateFormat);
      localStorage.setItem("fromDate", startOfMonth);
      localStorage.setItem("toDate", endOfMonth);
      localStorage.setItem(
        "fromDateUnix",
        new Date(startOfMonth).getTime() / 1000
      );
      localStorage.setItem(
        "toDateUnix",
        new Date(endOfMonth).getTime() / 1000 + 86400
      );
    }
    setInterval(() => setloader("noloader"), 1000);
  }, []);

  useEffect(() => {
    axios
      .post(`${getServerUrl()}/checkuserlogin`, "", {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;

        if (res.data.success === 1) {
          setuserLogin(true);
        }
      });
  }, []);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const DateSelect = (value, dateString) => {
    localStorage.setItem(
      "fromDateUnix",
      new Date(dateString[0]).getTime() / 1000
    );
    localStorage.setItem(
      "toDateUnix",
      new Date(dateString[1]).getTime() / 1000 + 86400
    );
    localStorage.setItem("fromDate", dateString[0]);
    localStorage.setItem("toDate", dateString[1]);
    window.location.reload();
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideBar />
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          <div className="mobile-logo">
            <a href="/">
              <img src={logo}></img>
            </a>
          </div>
          {/* <div className="item">
           
  </div> */}
        </Header>
        <div className={loader}>
          <Spin size="large" tip="Loading..."></Spin>
        </div>
        <Content style={{ margin: "0 16px", paddingBottom: "200px" }}>
          <div>
            <Row justify="center">
              <Col xs={24} xl={20}>
                <div className="mobile-only">
                  <Row style={{ cursor: "pointer" }}>
                    <Col xs={4} xl={8} onClick={showDrawer}>
                      <Avatar
                        size="large"
                        style={{ backgroundColor: "#a06488" }}
                        icon={<UserOutlined />}
                      />
                    </Col>
                    <Col xs={16} xl={8} onClick={showDrawer}>
                      <div>
                        <p style={{ color: "#a06488" }}>
                          <b> {localStorage.getItem("username")}</b>
                          <br></br>
                          {localStorage.getItem("Organization")}
                        </p>
                      </div>
                    </Col>
                    <Col xs={4} xl={24} onClick={refreshPage}>
                      <Avatar
                        style={{ backgroundColor: "#fff", color: "#C12026" }}
                        size="large"
                        icon={<RedoOutlined />}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={16} xl={8}>
                <div className="mobileCenter">
                  <RangePicker
                    defaultValue={[
                      moment(localStorage.getItem("fromDate"), dateFormat),
                      moment(localStorage.getItem("toDate"), dateFormat),
                    ]}
                    format={dateFormat}
                    onChange={DateSelect}
                    style={{ width: "100%" }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <Switch>
            <Route path="/in-stock" component={InStock}></Route>
            <Route path="/viewinward/:id" component={ViewInward}></Route>
            <Route path="/schedule" component={Schedule}></Route>
            <Route
              path="/logistics"
              component={userState === "contractor" ? OutWard : Home}
            ></Route>
            <Route path="/inventory" component={Inventory}></Route>
            <Route
              path="/stockdetail/:component/:process"
              component={StockDetail}
            ></Route>
            <Route
              path="/readystock"
              component={userState === "contractor" ? ReadyStock : Home}
            ></Route>
            <Route
              path="/report"
              component={
                userState === "contractor" ? VendorReport : DriverReport
              }
            ></Route>
            <Route path="/trips" component={Trips}></Route>
            <Route path="/completed-trips" component={CompletedTrips}></Route>
            <Route path="/upcomingtrips" component={UpcomingTrips}></Route>
            <Route path="/viewtrip/:id" component={TripDetail}></Route>
            <Route path="/tasks" component={Tasks}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center" }}>Sarang Auto © 2021</Footer>
        {userState === "contractor" ? <MobileMenu /> : <MobileMenuDriver />}
      </Layout>
      <Drawer
        title="My Account"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <h3>{localStorage.getItem("username")}</h3>
        {localStorage.getItem("Organization")} <br></br>
        <br></br>
        <br></br>
        <a href="#" className="logout" onClick={logout}>
          Logout
        </a>
      </Drawer>{" "}
    </Layout>
  );
};
export default Dashboard;
