import { Row, Col, Button } from "antd";
import React, { useState } from "react";
import "./stockCard.scss";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
const TripCard = (pros) => {
  function updateTrip(id, status) {
    axios
      .post(
        `${getServerUrl()}/vendor/updatetrip/`,
        { tripid: id, status: status },
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          if (status === "Accept") {
            window.location.href = "/viewtrip/" + id;
          } else {
            window.location.reload();
          }
        }
      });
  }

  return (
    <div className="tripcard">
      <div className="cardTitle">
        <Link
          to={pros.tripstatus !== "Reject" ? "/viewtrip/" + pros.tripid : "#"}
        >
          <h3 style={{ color: "#fff", padding: "0" }}>
            <b>Trip Date :</b>
            {pros.trip_date}
          </h3>
        </Link>
      </div>
      <div className="carddetail">
        <p>
          <b>Trip ID: </b> {pros.tripid}
        </p>
        <p>
          <b>Assigned Task(s): </b> {pros.tasks}
        </p>
        <p>
          <b>Completed Task(s): </b>
          {pros.completed}
        </p>
        <p>
          <b>Remain Task(s): </b>
          {pros.remain}
        </p>

        <Row
          className={
            pros.tripstatus === "Accept" || pros.tripstatus === "Reject"
              ? "display-none"
              : ""
          }
        >
          <Col span={24}>
            <Button
              onClick={() => updateTrip(pros.tripid, "Accept")}
              type="primary"
              className="buttonFull accept"
            >
              {" "}
              Accept
            </Button>{" "}
          </Col>
          <Col span={24}>
            <Button
              onClick={() => updateTrip(pros.tripid, "Reject")}
              className="rejectbutton buttonFull"
            >
              {" "}
              Reject
            </Button>{" "}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TripCard;
