import React, { Component, useEffect, useState } from "react";
import { Statistic, Row, Col, Button, Breadcrumb, Select } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TaskCard from "../components/taskcard";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const ViewInward = (props) => {
  const { id } = props.match.params;
  let [rawstock, setrawstock] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getorderbystockmaterial/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setrawstock(details.data);
        }
      });
  }, []);
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>View Tasks</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <Row style={{ minHeight: 200 }}>
          {rawstock.map((tasklist) => (
            <Col xs={24} xl={8}>
              <TaskCard
                component_name={tasklist.component_name}
                component_no={tasklist.component_no}
                process_name={tasklist.process_name}
                processdesc={tasklist.processdesc}
                component_weight={tasklist.component_weight}
                quantity={tasklist.quantity}
                weight={tasklist.weight}
                raw_material_name={tasklist.raw_material_name}
                rawvariant={tasklist.rawvariant}
                rawbatch={tasklist.rawbatch}
                order_id={tasklist.order_id}
                order_status={tasklist.order_status}
                raw_material_type_unit={tasklist.raw_material_type_unit}
                stid={tasklist.stid}
              />
            </Col>
          ))}
        </Row>
        <Row></Row>
      </div>
    </div>
  );
};

export default ViewInward;
