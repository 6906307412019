import { Row, Col, Button } from "antd";
import React, { useState } from "react";
import "./stockCard.scss";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import axios from "axios";
import { getServerUrl } from "../config";
const StockCard = (pros) => {
  const [activePO, setactivePO] = useState(false);
  const CheckQuantity = (props) => {
    axios
      .get(`${getServerUrl()}/vendor/getorderbystockmaterial/${props.id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("Stock Length", details.data.length);
          if (details.data.length >= 1) {
            setactivePO(true);
          } else {
            setactivePO(false);
          }
        }
      });

    return activePO ? (
      <Button
        className="primary"
        style={{ background: "#008204", color: "#fff" }}
      >
        View Schedule
      </Button>
    ) : (
      ""
    );
  };

  return (
    <Link
      to={"/viewinward/" + pros.dc}
      className={activePO ? "" : "display-none"}
    >
      <div className="stockCard">
        <div className="cardTitle">
          <Row>
            <Col xs={12} xl={12}>
              <b>Raw Material Consignment :</b> {pros.rawbatch}
            </Col>
            <Col xs={12} xl={12} className="text-right">
              <b>DC :</b> # {pros.dc}
            </Col>
          </Row>
        </div>
        <div className="carddetail">
          <p>
            <b>Raw Material Type: </b>
            {pros.raw_material_type_name}
          </p>
          <p>
            <b>Raw Material: </b>
            {pros.raw_material_name}
          </p>

          <p>
            <b>Raw Variant: </b> {pros.rawvariant}
          </p>
          <p>
            <b>Raw Batch: </b> {pros.rawbatch}
          </p>
          <p>
            <b>Quantity: </b> {pros.quantity} {pros.raw_material_type_unit}
          </p>
          <CheckQuantity id={pros.dc} />
        </div>
      </div>
    </Link>
  );
};

export default StockCard;
