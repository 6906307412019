import React, { Component, useEffect, useState } from "react";
import { Statistic, Breadcrumb, Select, Space, Table } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TripDetailCard from "../components/tripdetail";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const ReadyStock = (props) => {
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [vendorName, setvendorName] = useState("");

  const [data, setdata] = useState("");

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/vendorreadystock/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdetails(details);
      });
  }, []);
  const columns = [
    {
      title: "Component ID & Name",
      dataIndex: "component_no",
      key: "component_no",
      render: (text, record) => (
        <Space size="middle">
          <b>
            {record.component_no} - {record.component_name}
          </b>
        </Space>
      ),
    },

    {
      title: "Process",
      dataIndex: "process_name",
      key: "process_name",
    },
    {
      title: "Ready Stock",
      dataIndex: "readystock",
      key: "stock",
      render: (text, record) => (
        <Space size="middle">
          <b>{record.stock}</b>
        </Space>
      ),
    },
    /*  {
          title: "Action",
          dataIndex: "contractorid",
          key: "contractorid",
          render: (text, record) => (
            <Space size="middle">
              <NavLink to={"/viewbycsid/" + record.contractorid}>
                View in details
              </NavLink>
            </Space>
          ),
        },*/
  ];

  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory </Breadcrumb.Item>
        <Breadcrumb.Item>Ready Statement</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          READY<span> STOCK</span>
        </h2>
        <Space></Space>
        <br />
        <Table columns={columns} dataSource={details}></Table>
      </div>
    </div>
  );
};

export default ReadyStock;
