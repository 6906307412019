import React, { Component } from "react";
import { Layout } from "antd";
import Dashboard from "./dashboard";
import Login from "./login";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import { getServerUrl } from "./config";
import axios from "axios";
const { Header, Content, Footer } = Layout;

class App extends Component {
  state = {
    auth: localStorage.getItem("isLogin"),
    token: localStorage.getItem("token"),
  };

  render() {
    if (this.state.token === undefined) {
      localStorage.clear();
      window.location.reload();
    }
    return <Router>{this.state.auth ? <Dashboard /> : <Login />}</Router>;
  }
}
export default App;
