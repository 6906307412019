import React from "react";
import "./sidebar.css";

import { NavLink } from "react-router-dom";

class MobileMenu extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };
  render() {
    const { collapsed } = this.state;
    return (
      // collapsible collapsed={collapsed} onCollapse={this.onCollapse}
      <div className="mobile-navbar">
        <ul>
          <li>
            <NavLink to="/in-stock/" exact>
              <span className="icons icon-assign-vehicle-start"></span>
              <span className="menu-text">Inward</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/upcomingtrips/" exact>
              <span className="icons icon-mobile-logistics"></span>
              <span className="menu-text">Outward</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/" exact>
              <span className="icons icon-mobile-home"></span>
              <span className="menu-text">Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/inventory/" exact>
              <span className="icons icon-mobile-vendor"></span>
              <span className="menu-text">Inventory</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/logistics/" exact>
              <span className="icons icon-finished-goods"></span>
              <span className="menu-text">Ready</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
export default MobileMenu;
