import React, { Component, useEffect, useState } from "react";
import { Statistic, Row, Col, Button, Breadcrumb, Select } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import StockCard from "../components/stockcard";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const HomePage = () => {
  const [userState, setuserState] = useState("");
  let [rawstock, setrawstock] = React.useState(initialList);
  useEffect(() => {
    setuserState(localStorage.getItem("usertype"));
  }, []);

  const ContractorMenu = () => {
    return (
      <div className="site-layout-background">
        <Row style={{ minHeight: 200 }}>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/in-stock" className="LinksCardInner">
                <span className="cardText">
                  <b>Inward</b>
                  <br></br> Logistics
                </span>
                <span className="icons icon-assign-vehicle-start"></span>
              </NavLink>
            </div>
          </Col>

          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/logistics" className="LinksCardInner">
                <span className="cardText">
                  <b>Update</b>
                  <br></br> Ready Stock
                </span>{" "}
                <span className="icons icon-rm-receipt"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/readystock" className="LinksCardInner">
                <span className="cardText">
                  <b>View </b>
                  <br></br> Ready Stock
                </span>
                <span className="icons icon-inventory"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/upcomingtrips" className="LinksCardInner">
                <span className="cardText">
                  <b>Outward </b> <br></br>Logistics
                </span>
                <span className="icons icon-logistics"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/inventory" className="LinksCardInner">
                <span className="cardText">
                  <b>Inventory</b> <br></br>Report
                </span>
                <span className="icons icon-component-data"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/schedule" className="LinksCardInner">
                <span className="cardText">
                  <b>Schedule</b>
                </span>
                <span className="icons icon-mobile-setting"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/report" className="LinksCardInner">
                <span className="cardText">
                  <b>REPORT</b>
                </span>
                <span className="icons icon-purchase-order"></span>
              </NavLink>
            </div>
          </Col>
        </Row>
        <Row></Row>
      </div>
    );
  };
  const Transporter = () => {
    return (
      <div className="site-layout-background">
        <Row style={{ minHeight: 200 }}>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/trips" className="LinksCardInner">
                <span className="cardText">
                  <b>New</b> Trips
                </span>
                <span className="icons icon-finished-goods"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/completed-trips" className="LinksCardInner">
                <span className="cardText">
                  <b>Completed</b> Trips
                </span>
                <span className="icons icon-finished-goods"></span>
              </NavLink>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <div className="LinksCard">
              <NavLink to="/report" className="LinksCardInner">
                <span className="cardText">
                  <b>REPORT</b>
                </span>
                <span className="icons icon-purchase-order"></span>
              </NavLink>
            </div>
          </Col>
        </Row>
        <Row></Row>
      </div>
    );
  };
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      {userState === "contractor" ? (
        <ContractorMenu></ContractorMenu>
      ) : (
        <Transporter></Transporter>
      )}
    </div>
  );
};

export default HomePage;
