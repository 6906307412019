import React, { Component, useEffect, useState } from "react";
import {
  Statistic,
  Breadcrumb,
  Select,
  Space,
  Button,
  Table,
  Modal,
  Input,
  message,
} from "antd";
import { PlusCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TripDetailCard from "../components/tripdetail";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const UpcomingTrips = (props) => {
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [vendorName, setvendorName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuantityModalVisible, setIsQuantityModalVisible] = useState(false);
  const [transid, settransid] = useState("");
  const [dcno, setdcno] = useState("");
  const [quantity, setquantity] = useState("");
  const [transquantity, settransquantity] = useState("");
  const [data, setdata] = useState("");
  const updatedcno = (transno, transquantity) => {
    setIsModalVisible(true);
    settransid(transno);
    settransquantity(transquantity);
  };
  const updatedquanity = (transno) => {
    setIsQuantityModalVisible(true);
    settransid(transno);
  };
  const handleOk = () => {
    if (
      dcno !== "" &&
      quantity !== "" &&
      Number(transquantity) >= Number(quantity)
    ) {
      axios
        .post(
          `${getServerUrl()}/vendor/updatedc/`,
          { transno: transid, dcno: dcno, quantity: quantity },
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          const details = res.data;
          if (res.data.success === 1) {
            window.location.reload();
          }
        });
      setIsModalVisible(false);
    } else {
      message.error("Please enter valid DC & Quantity");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getupcomingtrip/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;

        setdetails(details);
      });
  }, []);
  const columns = [
    {
      title: "Trip Date",
      dataIndex: "transdate",
      key: "transdate",
    },
    {
      title: "Sarang DC",
      render: (text, record) => (
        <Space size="middle">
          <Button type="secondary">
            {record.tripid} / {record.transid}
          </Button>
        </Space>
      ),
      width: "15em",
    },
    {
      title: "Vendor DC",
      dataIndex: "instock",
      render: (text, record) => (
        <Space size="middle">
          {record.vendor_dc !== "-" ? record.vendor_dc : <em>Not Assigned</em>}
        </Space>
      ),
    },
    {
      title: "Confirmed Quantity",
      dataIndex: "source_confirm_quantity",
      key: "source_confirm_quantity",
      render: (text, record) => (
        <Space size="middle">
          {record.source_confirm_quantity &&
          record.source_confirm_quantity !== "-" ? (
            record.source_confirm_quantity
          ) : (
            <em>Not Confirmed</em>
          )}
        </Space>
      ),
    },
    {
      title: "Component no",
      dataIndex: "component_no",
      key: "component_no",
    },
    {
      title: "Component Name",
      dataIndex: "component_name",
      key: "component_name",
    },
    {
      title: "Process",
      dataIndex: "process_name",
      key: "process_name",
    },
    {
      title: "Assigned Quantity",
      dataIndex: "instock",
      key: "instock",
    },

    {
      title: "Transporter",
      dataIndex: "transporter_name",
      key: "transporter_name",
    },

    {
      title: "Driver",
      dataIndex: "driver_name",
      key: "driver_name",
    },
    {
      title: "Driver Contact",
      dataIndex: "driver_phone",
      key: "driver_phone",
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle_number",
      key: "vehicle_number",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
    },
    {
      title: "Pickup time",
      dataIndex: "pickuptime",
      key: "pickuptime",
    },
    {
      title: "Add DC & Quantity",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          {record.vendor_dc === "-" ? (
            <PlusCircleFilled
              style={{ fontSize: "2em", color: "#bc0067" }}
              onClick={() => updatedcno(record.transid, record.instock)}
            />
          ) : (
            <CheckCircleFilled style={{ fontSize: "2em", color: "#9fe100" }} />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Outward Logistics</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          OUTWARD<span> LOGISTICS</span>
        </h2>
        <Space></Space>
        <br />
        <Table
          scroll={{ x: 1300 }}
          className="customTable"
          columns={columns}
          dataSource={details}
        ></Table>
      </div>
      <Modal
        title={"Add DC"}
        visible={isModalVisible}
        okText="Start Trip"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <label>DC No</label>
          <Input
            name="DC NO"
            placeholder="DC No"
            value={dcno}
            onChange={(e) => {
              setdcno(e.target.value);
            }}
          ></Input>
          <br></br>
          <label>Confirm Quantity</label>
          <Input
            name="Confirm Quantity"
            placeholder="Confirm Quantity"
            value={quantity}
            onChange={(e) => {
              setquantity(e.target.value);
            }}
          ></Input>
        </div>
      </Modal>
    </div>
  );
};

export default UpcomingTrips;
