import React, { Component, useEffect, useState } from "react";
import { Statistic, Row, Col, Button, Breadcrumb, Select } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import StockCard from "../components/stockcard";
import InStockCard from "../components/instockcard";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const InStock = () => {
  let [rawstock, setrawstock] = React.useState(initialList);
  let [vendorStock, setvendorStock] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getmaterialstockbyvendor/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setrawstock(details.data);
        }
      });
    axios
      .get(`${getServerUrl()}/vendor/stocktovendor/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("details.data", details.data);
          setvendorStock(details.data);
        }
      });
  }, []);
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inward Logistics</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className={
          rawstock.length > 0
            ? "site-layout-background displaynone"
            : "site-layout-background"
        }
      >
        <h2 className="titles">
          INWARD<span> LOGISTICS</span>
        </h2>
      </div>

      <div
        className={
          rawstock.length > 0
            ? "site-layout-background"
            : "site-layout-background displaynone"
        }
      >
        <h2 className="titles">
          INWARD<span> LOGISTICS</span>
        </h2>
        <Row>
          {rawstock ? (
            rawstock.map((rawstocklist) => (
              <StockCard
                rawbatch={rawstocklist.rawbatch}
                raw_material_name={rawstocklist.raw_material_name}
                rawvariant={rawstocklist.rawvariant}
                quantity={rawstocklist.quantity}
                transit={rawstocklist.transit}
                dc={rawstocklist.stid}
                raw_material_type_name={rawstocklist.raw_material_type_name}
                raw_material_type_unit={rawstocklist.raw_material_type_unit}
              />
            ))
          ) : (
            <p>No New Data</p>
          )}
        </Row>
        <Row></Row>
      </div>
      <div className="site-layout-background">
        <Row>
          {vendorStock ? (
            vendorStock.map((rawstocklist) => (
              <Col xs={24} xl={8}>
                <InStockCard
                  component_name={rawstocklist.component_name}
                  dc={rawstocklist.transid}
                  create_date={rawstocklist.create_date}
                  component_no={rawstocklist.component_no}
                  instock={rawstocklist.source_confirm_quantity}
                  process_name={rawstocklist.process_name}
                  transid={rawstocklist.transid}
                />
              </Col>
            ))
          ) : (
            <p>No New Data</p>
          )}
        </Row>
        <Row></Row>
      </div>
    </div>
  );
};

export default InStock;
