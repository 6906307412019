import React, { Component, useEffect, useState } from "react";
import {
  Statistic,
  Table,
  Row,
  Col,
  Space,
  Button,
  Breadcrumb,
  Select,
  DatePicker,
} from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import moment from "moment";
import { getServerUrl } from "../config";
import axios from "axios";
const initialList = [];
const { RangePicker } = DatePicker;
const CompletedTrips = () => {
  const dateFormat = "YYYY/MM/DD";
  let [data, setdata] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(
        `${getServerUrl()}/vendor/getdrivercompletedtrip/${localStorage.getItem(
          "fromDateUnix"
        )}/${localStorage.getItem("toDateUnix")}`,
        {
          headers: { "x-auth-token": localStorage.getItem("token") },
        }
      )
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setdata(details.data);
        }
      });
  }, []);

  const DateSelect = (value, dateString) => {
    localStorage.setItem(
      "fromDateUnix",
      new Date(dateString[0]).getTime() / 1000
    );
    localStorage.setItem(
      "toDateUnix",
      new Date(dateString[1]).getTime() / 1000
    );
    localStorage.setItem("fromDate", dateString[0]);
    localStorage.setItem("toDate", dateString[1]);
    window.location.reload();
  };
  const columns = [
    {
      title: "S. No",
      dataIndex: "index",
      render: (text, record, index) => Number(index + 1),
    },
    {
      title: "Trip Date",
      dataIndex: "transdate",
      key: "transdate",
    },
    {
      title: "Sarang DC",
      render: (text, record) => (
        <Space size="middle">
          <Button type="secondary">
            {record.tripid} / {record.transid}
          </Button>
        </Space>
      ),
    },
    {
      title: "Vendor DC",
      dataIndex: "vendor_dc",
      key: "vendor_dc",
    },
    {
      title: "Source",
      dataIndex: "source_name",
      key: "source_name",
    },
    {
      title: "Destination",
      dataIndex: "destination_name",
      key: "destination_name",
    },
    {
      title: "Component no",
      dataIndex: "component_no",
      key: "component_no",
    },
    {
      title: "Component Weight",
      dataIndex: "rawmaterial_weight",
      key: "rawmaterial_weight",
    },
    {
      title: "Pickup Quantity",
      dataIndex: "source_confirm_quantity",
      key: "source_confirm_quantity",
    },
    {
      title: "Delivered Quantity",
      dataIndex: "destination_confirm_quantity",
      key: "destination_confirm_quantity",
    },

    {
      title: "Total. Weight",
      dataIndex: "rawmaterial_weight",
      key: "rawmaterial_weight",
      render: (text, record) => (
        <Space size="middle">
          {Number(record.destination_confirm_quantity) *
            Number(record.rawmaterial_weight)}
        </Space>
      ),
    },
  ];
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Completed Trips</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <br></br>
        <div className="mobileCenter">
          {" "}
          Select Date:{" "}
          <RangePicker
            defaultValue={[
              moment(localStorage.getItem("fromDate"), dateFormat),
              moment(localStorage.getItem("toDate"), dateFormat),
            ]}
            format={dateFormat}
            onChange={DateSelect}
          />
        </div>
        <br></br>
        Total No.of Completed Trips: <b>{data.length}</b>
        <br></br>
        <br></br>
        <Table scroll={{ x: 1300 }} columns={columns} dataSource={data}></Table>
      </div>
    </div>
  );
};

export default CompletedTrips;
