import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
import { Table, Space, Breadcrumb, Input, Button, Select, Col } from "antd";

import { CSVLink } from "react-csv";

const { Option } = Select;

const VendorReport = (props) => {
  const [details, setdetails] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [componentNo, setcomponentNo] = useState("");
  const [componentName, setcomponentName] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);

  const [data, setdata] = useState("");

  useEffect(() => {
    if (localStorage.getItem("fromDateUnix")) {
      axios
        .get(
          `${getServerUrl()}/vendor/getcompletedtripbycontractor/${localStorage.getItem(
            "fromDateUnix"
          )}/${localStorage.getItem("toDateUnix")}`,
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          let responsevalue = res.data.data;
          const details = responsevalue;
          setdetails(details);
        });
    }
  }, []);

  const columns = [
    /*{
      title: "Trip Date",
      dataIndex: "transdate",
      key: "transdate",
    },**/
    {
      title: "Component",
      dataIndex: "component_no",
      render: (text, record) =>
        record.component_no + "\n" + record.component_name,
    },
    {
      title: "Process Name",
      dataIndex: "processName",
      key: "processName",
    },

    {
      title: "Quantity",
      dataIndex: "destination_confirm_quantity",
      key: "destination_confirm_quantity",
    },
    {
      title: "Price",
      dataIndex: "destination_confirm_quantity",
      render: (text, record) =>
        Number(record.destination_confirm_quantity * record.rate).toFixed(2),
    },
    {
      title: "Action",
      dataIndex: "contractorid",
      key: "contractorid",
      render: (text, record) => (
        <Space size="middle">
          <NavLink
            to={
              "/stockdetail/" + record.component_id + "/" + record.processName
            }
          >
            View in details
          </NavLink>
        </Space>
      ),
    },
  ];

  var s = new Date(
    localStorage.getItem("fromDateUnix") * 1000
  ).toLocaleDateString("en-US");
  var e = new Date(
    localStorage.getItem("toDateUnix") * 1000
  ).toLocaleDateString("en-US");
  const TableHeaders = [
    { label: "Component ID & Component Name", key: "componentName" },
    { label: "Process", key: "processName" },
    { label: "Quantity", key: "destination_confirm_quantity" },
    { label: "PO Rate", key: "rate" },
    { label: "Total Price", key: "totalPrice" },
  ];
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Report </Breadcrumb.Item>
        <Breadcrumb.Item>Contractor Report</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">Contractor Report</h2>
        <br></br>
        <Space></Space>
        <br />

        {details.length > 0
          ? "Contractor Name : " + details[0].source_name
          : "No Record Found"}
        <br />
        <br />
        {details.length > 0 ? (
          <Button type="primary" shape="round" size="large">
            <CSVLink
              filename={(
                "Contractor Report " +
                details[0].source_name +
                "-" +
                s +
                "-" +
                e
              ).replaceAll(".", "")}
              data={details}
              headers={TableHeaders}
            >
              Download Data
            </CSVLink>
          </Button>
        ) : (
          ""
        )}

        <br></br>
        <br />
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={details}
        ></Table>
      </div>
    </div>
  );
};

export default VendorReport;
