import React, { Component, useReducer, useEffect, useState } from "react";
import {
  Statistic,
  Row,
  Col,
  Button,
  Breadcrumb,
  Select,
  Input,
  notification,
  message,
  Modal,
} from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TaskCardView from "../components/taskcardview";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const OutWard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isComponentModalVisible, setisComponentModalVisible] = useState(false);
  let [component, setcomponent] = useState(initialList);
  let [requestquantity, setrequestquantity] = useState(0);
  let [remainingquantity, setremainingquantity] = useState(0);
  let [orderdetail, setorderdetail] = useState(initialList);
  let [componentlist, setcomponentlist] = useState(initialList);
  let [selectedcomponentlist, setselectedcomponentlist] = useState(initialList);

  const [componentName, setcomponentName] = useState("");
  const [componentNo, setcomponentNo] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [processName, setprocessName] = useState("");
  const [readyQuantity, setreadyQuantity] = useState("");
  const [batchNo, setbatchNo] = useState("");
  const [batchDate, setbatchDate] = useState("");
  const [remainQuantity, setremainQuantity] = useState("");
  const [nCR, setnCR] = useState("");
  const [completed, setcompleted] = useState("");
  const [userInput, setUserInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      ready_quantity: "",
      nonready_quantity: "",
      nonready_reason: "",
      batch: "",
      component_id: "",
      quantity: "",
      component_id: "",
      component_name: "",
      component_no: "",
      process_name: "",
      quantity: "",
      ncrquantity: "",
      readyquantity: "",
      cs_id: "",
      nonconformance: "",
      purchase_item_id: "",
      component_ready_quantity: "",
    }
  );
  const handleChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;
    setUserInput({ [name]: newValue });
  };
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getuniquematerial/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log(details.data);
          setcomponent(details.data);
        }
      });
  }, []);

  const componentpolistnew = component
    ? component.reduce((accumulator, current) => {
        if (!accumulator.some((x) => x.component_id === current.component_id)) {
          accumulator.push(current);
        }
        return accumulator;
      }, [])
    : "";

  const ComponentOption = componentpolistnew
    ? componentpolistnew.map((dataDrop) => {
        return (
          <Option value={dataDrop.component_id}>
            {dataDrop.component_no} - {dataDrop.component_name}
          </Option>
        );
      })
    : "";

  function ReadyComponentOptionChange(value) {
    axios
      .get(`${getServerUrl()}/vendor/getallorderstockmaterial/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setorderdetail(details.data);
        }
      });
  }

  const OrderOption = orderdetail.map((dataDrop) => {
    return (
      <Option value={dataDrop.order_id}>
        {dataDrop.stid}/{dataDrop.rawbatch}/{dataDrop.order_id}
      </Option>
    );
  });
  function OrderOptionChange(value) {
    axios
      .get(`${getServerUrl()}/vendor/getorderstockmaterialbyid/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        console.log(details);
        if (details) {
          userInput.component_id = details.data[0].component_id;
          userInput.batch =
            details.data[0].stid +
            "/" +
            details.data[0].rawbatch +
            "/" +
            details.data[0].order_id;
          userInput.component_name = details.data[0].component_name;
          userInput.component_no = details.data[0].component_no;
          userInput.process_name = details.data[0].process_name;
          userInput.order_id = details.data[0].order_id;
          userInput.purchase_item_id = details.data[0].purchase_item_id;
          setrequestquantity(Number(details.data[0].quantity));
          setremainingquantity(
            Number(details.data[0].remain_schedule_quantity)
          );
        }
      });
  }
  function nonconformanceChange(value) {
    userInput.nonready_reason = value;
  }
  const handleSubmit = (e) => {
    if (
      Number(userInput.ready_quantity) <= Number(remainingquantity) &&
      Number(userInput.ready_quantity) + Number(userInput.nonready_quantity) <=
        Number(remainingquantity)
    ) {
      setIsModalVisible(true);
    } else {
      message.error(
        "Please check the schedule quantity, before entering ready quantity"
      );
    }
  };

  /* Open Notifications */
  const openNotification = (type) => {
    notification[type]({
      message: "Pickup Request created",
      description: "Pickup Request created successfully",
    });
  };
  const FailNotification = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Fill out Required Fields",
    });
  };
  const StockNotavail = (type) => {
    notification[type]({
      message: "Error",
      description: "Please Check the remain stock, before enter ready quantity",
    });
  };

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/vendorstockselection/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        if (details) {
          setcomponentlist(details);
        }
      });
  }, []);

  const ComponentOptionSelect = componentlist.map((dataDrop) => {
    return (
      <Option value={dataDrop.component_id}>
        {dataDrop.component_no} - {dataDrop.component_name}
      </Option>
    );
  });
  //componentlist

  function ComponentSelectionChange(value) {
    setselectedcomponentlist(initialList);
    axios
      .get(`${getServerUrl()}/vendor/vendorstockselectionresult/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        if (details) {
          setselectedcomponentlist(details);
        }
      });
  }
  const ComponentListOption = selectedcomponentlist.map((dataDrop) => {
    return (
      <Option value={dataDrop.cs_id}>
        DC-{dataDrop.transid} | {dataDrop.processName}
      </Option>
    );
  });
  function ComponentOptionChange(value) {
    userInput.cs_id = value;
    axios
      .get(`${getServerUrl()}/vendor/getcomponentstockbyid/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        console.log(details);
        setcomponentName(details[0].component_name);
        setcomponentNo(details[0].component_no);
        setprocessName(details[0].processName);
        setQuantity(details[0].quantity);
        setbatchNo(details[0].batch_no);
        setbatchDate(details[0].create_date);
        setreadyQuantity(details[0].readystock);
        setremainQuantity(details[0].remainstock);
        setnCR(details[0].ncr);
        setcompleted(details[0].completed);
      });
  }

  function RMComponentOptionChange(value) {
    axios
      .get(`${getServerUrl()}/vendor/getallorderstockmaterial/${value}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          console.log("", details);
          setorderdetail(details.data);
        }
      });
  }
  const handleReadySubmit = (e) => {
    if (
      Number(remainQuantity - nCR - readyQuantity) >=
        Number(userInput.component_ready_quantity) &&
      Number(userInput.component_ready_quantity) +
        Number(userInput.ncrquantity) <=
        Number(remainQuantity - nCR - readyQuantity)
    ) {
      setisComponentModalVisible(true);
    } else {
      message.error("Please check the available quantity");
    }
  };

  const handleOk = () => {
    axios
      .post(`${getServerUrl()}/vendor/createcomponentstock`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          setUserInput({
            component_family: "",
          });
          openNotification("success");
          window.location.reload();
        }
      });
    setIsModalVisible(false);
  };

  const handleCOk = () => {
    userInput.ready_quantity = userInput.component_ready_quantity;
    axios
      .post(`${getServerUrl()}/vendor/updatereadystock`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          window.location.href = "/readystock";
        } else {
        }
      });
    setisComponentModalVisible(false);
  };
  const reworkSubmit = () => {
    axios
      .post(`${getServerUrl()}/vendor/requestrework`, userInput, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const data = res.data;
        if (data.success === 1) {
          window.location.href = "/logistics";
        } else {
        }
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCCancel = () => {
    setisComponentModalVisible(false);
  };
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Outward Stock</Breadcrumb.Item>
      </Breadcrumb>
      <Modal
        title="Please confirm "
        visible={isModalVisible}
        onOk={handleOk}
        okText="Confirm"
        onCancel={handleCancel}
      >
        <p style={{ color: "#303030" }}>
          Component No & Name :{" "}
          <b>
            {userInput.component_no} - {userInput.component_name}{" "}
          </b>
        </p>
        <p style={{ color: "#303030" }}>
          Process Name : <b>{userInput.process_name}</b>{" "}
        </p>
        <p style={{ color: "#303030" }}>
          Ready Quantity : <b>{userInput.ready_quantity}</b>{" "}
        </p>
        <p style={{ color: "#303030" }}>
          Non-Conformance Quantity : <b>{userInput.nonready_quantity}</b>{" "}
        </p>
      </Modal>

      <Modal
        title="Please confirm "
        visible={isComponentModalVisible}
        onOk={handleCOk}
        okText="Confirm"
        onCancel={handleCCancel}
      >
        <p style={{ color: "#303030" }}>
          Component No & Name :{" "}
          <b>
            {componentNo} - {componentName}{" "}
          </b>
        </p>
        <p style={{ color: "#303030" }}>
          Process Name : <b>{processName}</b>{" "}
        </p>
        <p style={{ color: "#303030" }}>
          Ready Quantity : <b>{userInput.component_ready_quantity}</b>{" "}
        </p>
        <p style={{ color: "#303030" }}>
          Non-Conformance Quantity : <b>{userInput.ncrquantity}</b>{" "}
        </p>
      </Modal>

      <div
        className={
          component && component.length > 0
            ? "site-layout-background"
            : "site-layout-background displaynone"
        }
      >
        <h1 style={{ color: "#bc0067" }}>
          Raw Material -&#62; Component Update
        </h1>
        <Row style={{ minHeight: 200 }}>
          <Col xs={24} xl={8}>
            <label>Component:</label>
            <br></br>
            <Select
              name="cp,"
              onChange={RMComponentOptionChange}
              placeholder="Please Select Component"
              style={{ width: "250px" }}
            >
              <Option value="">Please Select</Option>
              {ComponentOption}
            </Select>{" "}
            <br></br>
            <label>Batch No:</label>
            <br></br>
            <Select
              name="purchase_name"
              onChange={OrderOptionChange}
              placeholder="Please Select Batch"
              style={{ width: "250px" }}
            >
              <Option value="">Please Select</Option>
              {OrderOption}
            </Select>
            <br></br>
            <p>
              Schedule Quantity: {requestquantity !== 0 ? requestquantity : ""}{" "}
              <br></br>
              Remaining Quantity:{" "}
              {remainingquantity !== 0 ? remainingquantity : ""}
            </p>
            <label>Ready Quantity:</label>
            <br></br>
            <Input
              name="ready_quantity"
              placeholder="Ready Quantity"
              value={userInput.ready_quantity}
              onChange={handleChange}
            ></Input>
            <p>
              {userInput.ready_quantity > remainingquantity
                ? "Ready Quantity should be less than Available Qty"
                : ""}
            </p>
            <label>Non-Conformance Quantity:</label>
            <br></br>
            <Input
              name="nonready_quantity"
              placeholder="Non-Conformance Qty"
              value={userInput.nonready_quantity}
              onChange={handleChange}
            ></Input>
            <p>
              {userInput.nonready_quantity &&
              Number(userInput.ready_quantity) +
                Number(userInput.nonready_quantity) >
                remainingquantity
                ? "(Ready Qty + Non Confirmance Qty) Should be less than Available Qty"
                : ""}
            </p>
            <label>Non-Conformance Reason:</label>
            <br></br>
            <Select
              name="nonconformance"
              onChange={nonconformanceChange}
              placeholder="Please Select Raw Material Type"
              style={{ width: "250px" }}
            >
              <Option value="Rejection">Rejection</Option>
              <Option value="Non-Availability of Material">
                Non-Availability of Material
              </Option>
            </Select>{" "}
            <br></br>
            <br></br>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleSubmit}
            >
              Request Pickup
            </Button>
          </Col>
        </Row>
      </div>
      <div className="site-layout-background">
        <h1 style={{ color: "#bc0067" }}>Component Ready Stock Update</h1>
        <Row style={{ minHeight: 200 }}>
          <Col xs={24} xl={8}>
            <label>Component:</label>
            <br></br>
            <Select
              name="Componentname"
              onChange={ComponentSelectionChange}
              placeholder="Please Select Component"
            >
              <Option value="">Please Select</Option>
              {ComponentOptionSelect}
            </Select>{" "}
            <br></br>
            <label>Batch:</label>
            <br></br>
            <Select
              name="cp,"
              onChange={ComponentOptionChange}
              placeholder="Please Select Batch"
            >
              <Option value="">Please Select</Option>
              {ComponentListOption}
            </Select>{" "}
            <br></br>
            <label>Ready Quantity:</label>
            <br></br>
            <Input
              name="component_ready_quantity"
              placeholder="Ready Quantity"
              value={userInput.component_ready_quantity}
              onChange={handleChange}
            ></Input>
            <b style={{ color: "#ff0000" }}>
              {Number(remainQuantity - nCR - readyQuantity) <
              Number(userInput.component_ready_quantity)
                ? "Ready Quantity should be less than Available Qty"
                : ""}
            </b>
            <br></br>
            <label>Non-Conformance Quantity:</label>
            <br></br>
            <Input
              name="ncrquantity"
              placeholder="Non-Conformance Quantity"
              value={userInput.ncrquantity}
              onChange={handleChange}
            ></Input>
            <b style={{ color: "#ff0000" }}>
              {Number(remainQuantity - nCR - readyQuantity) <
              Number(userInput.component_ready_quantity) +
                Number(userInput.ncrquantity)
                ? "(Ready Qty + Non Confirmance Qty) Should be less than Available Qty"
                : ""}
            </b>
            <br></br> <br></br>
            <p style={{ fontSize: "18px", color: "#000" }}>
              <table>
                <tr>
                  <td width="60%">Process</td>
                  <td>:</td>
                  <td>
                    <b>{processName}</b>
                  </td>
                </tr>
                <tr>
                  <td>Total Quantity</td>
                  <td>:</td>
                  <td>
                    <b>{Quantity}</b>
                  </td>
                </tr>

                <tr>
                  <td>Outward Quantity</td>
                  <td>:</td>
                  <td>{completed}</td>
                </tr>
                <tr>
                  <td>NCR Quantity</td>
                  <td>:</td>
                  <td>{nCR}</td>
                </tr>
                <tr>
                  <td>Ready Quantity</td>
                  <td>:</td>
                  <td>
                    <b>{readyQuantity}</b>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>
                    Available Remaining<br></br> Quantity
                  </td>
                  <td>:</td>
                  <td>
                    <b style={{ color: "#00ad25" }}>
                      {remainQuantity
                        ? Number(remainQuantity - nCR - readyQuantity)
                        : "0"}{" "}
                    </b>
                  </td>
                </tr>
              </table>

              <br></br>
            </p>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleReadySubmit}
            >
              Request Pickup
            </Button>
            &nbsp;
            {/* <Button
              type="secondary"
              shape="round"
              size="large"
              onClick={reworkSubmit}
            >
              Request Rework
            </Button> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OutWard;
