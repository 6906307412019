import { Row, Col, Button, Modal, Input, Form, message } from "antd";
import React, { useState } from "react";
import "./stockCard.scss";
import { getServerUrl } from "../config";
import axios from "axios";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
const InStockCard = (pros) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [quantity, setquantity] = useState("");
  const [cquantity, setcquantity] = useState("");
  const [currentId, setcurrentId] = useState("");
  function acceptStock(id, quantity) {
    setcurrentId(id);
    setcquantity(quantity);
    setIsModalVisible(true);
  }
  const handleOk = () => {
    if (Number(cquantity) >= Number(quantity)) {
      axios
        .post(
          `${getServerUrl()}/vendor/acceptstock/`,
          { trans_id: currentId, quantity: quantity },
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          const details = res.data;
          if (res.data.success === 1) {
            window.location.reload();
          } else {
            message.error("Stock NOT allowed to accept, please contact Sarang");
          }
        });
      setIsModalVisible(false);
    } else {
      message.error("Please enter valid Quantity");
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="stockCard">
      <div className="cardTitle">
        <Row>
          <Col xs={24} xl={24}>
            <b>Input Stock :</b> {pros.component_no}
          </Col>
        </Row>
      </div>
      <div className="carddetail">
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>DC No: </b>
              {pros.dc}
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              <b>Date: </b>
              {pros.create_date}
            </p>
          </Col>
        </Row>

        <p>
          <b>Component No & Name: </b>
          {pros.component_no} - {pros.component_name}
        </p>
        <p>
          <b>Quantity: </b> {pros.instock}
        </p>
        <p>
          <b>Process: </b> {pros.process_name}
        </p>
        <Button
          onClick={() => acceptStock(pros.transid, pros.instock)}
          type="primary"
        >
          Accept Stock
        </Button>
      </div>
      <Modal
        title={"Add Destination"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <label>Received Quantity</label>
        <Input
          name="quantity"
          placeholder="Quantity"
          value={quantity}
          onChange={(e) => {
            setquantity(e.target.value);
          }}
        ></Input>
      </Modal>
    </div>
  );
};

export default InStockCard;
