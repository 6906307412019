import React, { Component, useEffect, useState } from "react";
import { Statistic, Row, Col, Button, Breadcrumb, Select } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TripDetailCard from "../components/tripdetail";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const TripDetail = (props) => {
  const { id } = props.match.params;
  let [rawstock, setrawstock] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/gettripdetail/${id}`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setrawstock(details.data);
        }
      });
  }, []);
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Trip Schedule</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <h2 style={{ textAlign: "center", color: "#000000 !important" }}>
          Total Tasks: {rawstock.length}
        </h2>
        <Row style={{ minHeight: 200 }}>
          {rawstock.map((tasklist, index) => (
            <Col xs={24} xl={8}>
              <TripDetailCard
                id={Number(index + 1)}
                source_name={tasklist.source_name}
                source_address={tasklist.source_address}
                destination_name={tasklist.destination_name}
                destination_address={tasklist.destination_address}
                component_name={tasklist.component_name}
                component_no={tasklist.component_no}
                weight={tasklist.rawmaterial_weight}
                droptime={tasklist.droptime}
                pickuptime={tasklist.pickuptime}
                stid={tasklist.transid}
                transid={tasklist.transid}
                quantity={tasklist.instock}
                source_confirm_quantity={tasklist.source_confirm_quantity}
                destination_confirm_quantity={
                  tasklist.destination_confirm_quantity
                }
                tripid={tasklist.tripid}
                tripstatus={tasklist.tripstatus}
                transstatus={tasklist.transstatus}
                vendor_dc={tasklist.vendor_dc}
                status={tasklist.status}
              />
            </Col>
          ))}
        </Row>
        <Row></Row>
      </div>
    </div>
  );
};

export default TripDetail;
