import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { Layout, Menu, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../sarang.svg";

import { BrowserRouter as Router, NavLink } from "react-router-dom";
const { Sider } = Layout;

const SideBar = () => {
  const [collapsed, setcollapsed] = useState(false);
  const [userState, setuserState] = useState("");
  const [userLogin, setuserLogin] = useState(false);
  useEffect(() => {
    setuserState(localStorage.getItem("usertype"));
  }, []);

  const onCollapse = (collapsed) => {
    console.log(collapsed);
    setcollapsed(collapsed);
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    // collapsible collapsed={collapsed} onCollapse={this.onCollapse}
    <Sider>
      <div className="logo">
        <img src={logo}></img>
      </div>
      <div className="accountLink">
        <Avatar size="90" icon={<UserOutlined />} />
        <p>
          <b>{localStorage.getItem("username")}</b>
        </p>
        <a href="#" className="logout" onClick={logout}>
          Logout
        </a>
      </div>
      {userState === "contractor" ? (
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <Menu.Item key="1">
            <span className="icons icon-home"></span>
            <NavLink to="/" exact>
              HOME
            </NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <span className="icons icon-assign-vehicle-start"></span>
            <NavLink to="/in-stock/" exact>
              INWARD
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <span className="icons icon-mobile-logistics"></span>
            <NavLink to="/upcomingtrips/" exact>
              OUTWARD
            </NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <span className="icons icon-mobile-vendor"></span>
            <NavLink to="/inventory/" exact>
              INVENTORY
            </NavLink>
          </Menu.Item>
          <Menu.Item key="5">
            <span className="icons icon-finished-goods"></span>
            <NavLink to="/logistics/" exact>
              READY
            </NavLink>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          <Menu.Item key="1">
            <span className="icons icon-home"></span>
            <NavLink to="/" exact>
              HOME
            </NavLink>
          </Menu.Item>
          <Menu.Item key="2">
            <span className="icons icon-assign-vehicle"></span>
            <NavLink to="/trips/" exact>
              TRIPS
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <span className="icons icon-assign-vehicle-new"></span>
            <NavLink to="/completed-trips/" exact>
              COMPLETED TRIPS
            </NavLink>
          </Menu.Item>
        </Menu>
      )}
    </Sider>
  );
};
export default SideBar;
