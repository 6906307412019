import { Row, Col, Button } from "antd";
import React from "react";
import "./stockCard.scss";
import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";
import { getServerUrl } from "../config";
import axios from "axios";
const TaskCardView = (pros) => {
  return (
    <div className="taskCard">
      <div className="carddetail">
        <Row>
          <Col xs={24} xl={24}>
            <p style={{ textAlign: "right" }}> Task # : {pros.order_id}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Component No & Name: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.component_no} - {pros.component_name}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Process: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.process_name}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Process Desc: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.processdesc}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Raw Material & Variant </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.raw_material_name} - {pros.rawvariant}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Raw Material Batch </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              {pros.stid}/{pros.rawbatch}/{pros.order_id}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Component Weight: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.component_weight} Kgs</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Quantity: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.quantity}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Completed Quantity: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.completed_quantity}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Remaining Quantity: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.remain_schedule_quantity}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12}>
            <p>
              <b>Non-Conformance Quantity: </b>
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>{pros.non_conformance_quantity}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TaskCardView;
