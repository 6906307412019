import React, { Component, useEffect, useState } from "react";
import { Statistic, Row, Col, Button, Breadcrumb, Select } from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TaskCardView from "../components/taskcardview";
import { getServerUrl } from "../config";
import axios from "axios";
const { Option } = Select;
const initialList = [];
const Tasks = (props) => {
  const { id } = props.match.params;
  let [rawstock, setrawstock] = React.useState(initialList);
  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getorderbyvendor`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setrawstock(details.data);
        }
      });
  }, []);
  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Tasks</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background">
        <Row style={{ minHeight: 200 }}></Row>
        <Row></Row>
      </div>
    </div>
  );
};

export default Tasks;
