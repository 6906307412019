import React, { useEffect, useState } from "react";
import {
  Statistic,
  Breadcrumb,
  Select,
  Space,
  Table,
  Input,
  Button,
} from "antd";
import { BrowserRouter as Router, NavLink } from "react-router-dom";
import TripDetailCard from "../components/tripdetail";
import { getServerUrl } from "../config";
import axios from "axios";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;
const initialList = [];
const Inventory = (props) => {
  const [details, setdetails] = useState("");
  const [rawstock, setrawstock] = useState("");
  const [newdetails, setnewdetails] = useState("");
  const [vendorName, setvendorName] = useState("");
  const [searchInput, setsearchInput] = useState([""]);
  const [searchText, setsearchText] = useState([""]);
  const [searchedColumn, setsearchedColumn] = useState([""]);
  const [data, setdata] = useState("");

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/vendorstock/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        let responsevalue = res.data.data;
        const details = responsevalue;
        setdetails(details);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${getServerUrl()}/vendor/getmaterialstockbyvendorgroup/`, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
      .then((res) => {
        const details = res.data;
        if (res.data.success === 1) {
          setrawstock(details.data);
        }
      });
  }, []);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setsearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setsearchedColumn(dataIndex);
    setsearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setsearchText("");
  };

  const rawcolumns = [
    {
      title: "Raw Material Name",
      dataIndex: "raw_material_name",
      key: "raw_material_name",
      ...getColumnSearchProps("raw_material_name"),
    },
    {
      title: "Raw Material Type",
      dataIndex: "raw_material_type_name",
      key: "raw_material_type_name",
      ...getColumnSearchProps("raw_material_type_name"),
    },
    {
      title: "Raw Material Variant",
      dataIndex: "rawvariant",
      key: "rawvariant",
      ...getColumnSearchProps("rawvariant"),
    },
    {
      title: "Inward Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) =>
        record.quantity + " " + record.raw_material_type_unit,
    },
    /* {
      title: "Schedule Qty",
      dataIndex: "usedquantity",
      render: (text, record) => (
        <Space size="middle">
          <b>
            {Number(record.usedquantity).toFixed(2)}{" "}
            {record.raw_material_type_unit}
          </b>
        </Space>
      ),
    },
    {
      title: "Balance Qty",
      dataIndex: "remainquantity",
      key: "remainquantity",
      render: (text, record) => (
        <Space size="middle">
          <b>
            {Number(record.remainquantity).toFixed(2)}{" "}
            {record.raw_material_type_unit}
          </b>
        </Space>
      ),
    },*/
    {
      title: "Outward Qty",
      dataIndex: "stid",
      key: "stid",
      render: (text, record) => (
        <GetMaterialOutWardQty
          raw_material_id={record.raw_material_id}
          variant={record.rawvariant}
          raw_material_type_unit={record.raw_material_type_unit}
        />
      ),
    },
    {
      title: "Closing Stock",
      dataIndex: "stid",
      key: "stid",
      render: (text, record) => (
        <GetMaterialOutWardQtyAlter
          raw_material_id={record.raw_material_id}
          variant={record.rawvariant}
          quantity={record.quantity}
          raw_material_type_unit={record.raw_material_type_unit}
        />
      ),
    },
  ];

  const columns = [
    {
      title: "Component No.",
      dataIndex: "component_no",
      key: "component_no",
      ...getColumnSearchProps("component_no"),
    },
    {
      title: "Component",
      dataIndex: "component_name",
      key: "component_name",
      ...getColumnSearchProps("component_name"),
    },
    {
      title: "Process",
      dataIndex: "processName",
      key: "processName",
      ...getColumnSearchProps("processName"),
    },
    {
      title: "Inward",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Outward",
      dataIndex: "completedstock",
      key: "completedstock",
    },
    {
      title: "Closing Stock",
      dataIndex: "completedstock",
      key: "completedstock",
      render: (text, record) =>
        Number(record.quantity) - Number(record.completedstock),
    },

    {
      title: "Action",
      render: (text, record) => (
        <Space size="middle">
          <NavLink
            to={
              "/stockdetail/" + record.component_id + "/" + record.processName
            }
          >
            View in details
          </NavLink>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <NavLink to="/" className="backButton">
        &lt; Back
      </NavLink>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Inventory</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          {details.length > 0 ? details[0].contractor_name : ""}
          <span> COMPONENT INVENTORY </span>
        </h2>
        <Space></Space>
        <br />
        <Table
          scroll={{ x: 1300 }}
          columns={columns}
          dataSource={details}
        ></Table>
      </div>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h2 className="titles">
          {rawstock.length > 0 ? rawstock[0].contractor_name : ""}
          <span> RAW MATERIAL INVENTORY </span>
        </h2>
        <Space></Space>
        <br />
        {rawstock.length ? (
          <Table
            scroll={{ x: 1300 }}
            columns={rawcolumns}
            dataSource={rawstock}
          ></Table>
        ) : (
          <p>No Raw Material Available</p>
        )}{" "}
      </div>
    </div>
  );
};

export default Inventory;

const GetMaterialOutWardQty = (prop) => {
  const [qty, setqty] = useState("");
  axios
    .get(
      `${getServerUrl()}/vendor/getmaterialoutwardqty/${prop.raw_material_id}/${
        prop.variant
      }`,
      {
        headers: { "x-auth-token": localStorage.getItem("token") },
      }
    )
    .then((res) => {
      if (res.data) {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setqty(details[0].outwardqty);
      } else {
        setqty(0);
      }
    });
  return Number(qty).toFixed(0) + " " + prop.raw_material_type_unit;
};

const GetMaterialOutWardQtyAlter = (prop) => {
  const [qty, setqty] = useState("");
  axios
    .get(
      `${getServerUrl()}/vendor/getmaterialoutwardqty/${prop.raw_material_id}/${
        prop.variant
      }`,
      {
        headers: { "x-auth-token": localStorage.getItem("token") },
      }
    )
    .then((res) => {
      if (res.data) {
        let responsevalue = Object.values(res.data.data);
        const details = responsevalue;
        setqty(details[0].outwardqty);
      } else {
        setqty(0);
      }
    });
  return (
    (Number(prop.quantity) - Number(qty)).toFixed(0) +
    " " +
    prop.raw_material_type_unit
  );
};
