import React, { useState } from "react";
import logo from "./sarangColor.svg";
import axios from "axios";
import { getServerUrl } from "./config";
import "./App.scss";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  notification,
} from "antd";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [message, setmessage] = useState("");
  const openNotification = (type) => {
    notification[type]({
      message: "Login Failed",
      description: "Invalid username or Password",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${getServerUrl()}/login`, {
        email: username,
        password: password,
      })
      .then((res) => {
        const data = res.data;
        //console.log(data);
        if (data.success === 1) {
          localStorage.setItem("token", data.token);
          localStorage.setItem("username", data.name);
          localStorage.setItem("usertype", data.usertype);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("Organization", data.Organization);
          window.location.reload();
        } else {
          openNotification("error");
        }
      });
  };

  return (
    <Layout>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: "100vh" }}
      >
        <Row>
          <Col span={8}></Col>
          <Col xs={24} xl={8} className="loginBox">
            <div className="loginLogo">
              <img src={logo} className="logoColor"></img>
              <br></br>
              <br></br>
              <h1>Vendor Login</h1>
            </div>

            <Form
              {...layout}
              name="basic"
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter username!",
                  },
                ]}
              >
                <Input
                  value={username}
                  onChange={(e) => {
                    setusername(e.currentTarget.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password!",
                  },
                ]}
              >
                <Input.Password
                  value={password}
                  onChange={(e) => {
                    setpassword(e.currentTarget.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <p className="te">{message}</p>
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Login;
