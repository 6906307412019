import { Row, Col, Button } from "antd";
import React, { useState } from "react";
import "./stockCard.scss";
import { getServerUrl } from "../config";
import axios from "axios";
const TripDetailCard = (pros) => {
  function TripActions(id, tripid, status) {
    if (status !== "") {
      axios
        .post(
          `${getServerUrl()}/vendor/updatetrans/`,
          { transid: id, tripid: tripid, status: status },
          {
            headers: { "x-auth-token": localStorage.getItem("token") },
          }
        )
        .then((res) => {
          const details = res.data;
          if (res.data.success === 1) {
            window.location.reload();
            //setloader("not-loading");
          }
        });
    }
  }
  function TimeCoverter(droptime) {
    if (droptime !== undefined) {
      let [hours, minutes] = droptime.split(":");
      var suffix = hours >= 12 ? "PM" : "AM";
      var hour = ((hours + 11) % 12) + 1 + suffix;
      var time = hour + " : " + minutes;
      return time;
    }
  }

  return (
    <div className="taskCard">
      <div className="carddetail">
        <Row>
          <Col xs={24} xl={24}>
            <p style={{ textAlign: "right" }}>
              {" "}
              <b>Task No:</b> # {pros.id}
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              <b>DC No.</b>
            </p>
            <p>{pros.transid}</p>
          </Col>{" "}
          <Col xs={12} xl={12}>
            <p>
              <b>Trip No.</b>
            </p>
            <p>{pros.tripid}</p>
          </Col>
          <Col xs={24} xl={24}>
            <p>
              <b>Pickup Location</b>
            </p>
            <p>
              {pros.source_name} - {pros.source_address}
            </p>
          </Col>
          <Col xs={24} xl={24}>
            <p>
              <b>Drop Location</b>
            </p>

            <p>
              {pros.destination_name} - {pros.destination_address}
            </p>
          </Col>
          <Col xs={24} xl={24}>
            <p>
              <b>Component No & Name</b>
            </p>

            <p>
              {pros.component_no} - {pros.component_name}
            </p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              <b>Pickup Time</b>
            </p>

            <p>{pros.pickuptime}</p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              <b>Drop Time</b>
            </p>

            <p>{pros.droptime}</p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              <b>Weight</b>
            </p>

            <p>{(Number(pros.weight) * Number(pros.quantity)).toFixed(2)}</p>
          </Col>
          <Col xs={12} xl={12}>
            <p>
              <b>Vendor DC</b>
            </p>

            <p>{pros.vendor_dc}</p>
          </Col>
          <Col xs={8} xl={8}>
            <p>
              <b>Assigned Qty</b>
            </p>

            <p>{pros.quantity}</p>
          </Col>
          <Col xs={8} xl={8}>
            <p>
              <b>Pickup Qty</b>
            </p>

            <p>{pros.source_confirm_quantity}</p>
          </Col>
          <Col xs={8} xl={8}>
            <p>
              <b>Delivered Qty</b>
            </p>

            <p>{pros.destination_confirm_quantity}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {pros.transstatus !== "Reject" &&
            pros.transstatus !== "Trip End" &&
            pros.vendor_dc === "-" ? (
              <Button
                onClick={() =>
                  TripActions(
                    pros.transid,
                    pros.tripid,
                    pros.transstatus === "Assigned" ? "Accept" : ""
                  )
                }
                type="primary"
                className="buttonFull accept"
              >
                {pros.transstatus === "Assigned" ? "Accept" : ""}
              </Button>
            ) : (
              ""
            )}

            {pros.status == "1" &&
            pros.vendor_dc !== "-" &&
            pros.transstatus !== "Trip End" &&
            pros.transstatus !== "Unloaded" ? (
              <Button
                onClick={() =>
                  TripActions(
                    pros.transid,
                    pros.tripid,
                    pros.transstatus === "Accept"
                      ? "Start Trip"
                      : "" || pros.transstatus === "Start Trip"
                      ? "Reach Destination"
                      : "" || pros.transstatus === "Reach Destination"
                      ? "Unloaded"
                      : "Unloaded"
                  )
                }
                type="primary"
                className="buttonFull accept"
              >
                {pros.transstatus === "Accept" ? "Start Trip" : ""}
                {pros.transstatus === "Start Trip" ? "Reach Destination" : ""}
                {pros.transstatus === "Reach Destination" ? "Unloaded" : ""}
              </Button>
            ) : (
              ""
            )}
            {pros.status === "2" &&
            pros.vendor_dc !== "-" &&
            pros.transstatus === "Unloaded" ? (
              <Button
                onClick={() =>
                  TripActions(
                    pros.transid,
                    pros.tripid,
                    pros.transstatus === "Unloaded" ? "Trip End" : ""
                  )
                }
                type="primary"
                className="buttonFull accept"
              >
                {pros.transstatus === "Unloaded" ? "Trip End" : ""}
              </Button>
            ) : (
              ""
            )}

            <p style={{ color: "red" }}>
              {pros.status == "1" &&
              pros.vendor_dc === "-" &&
              pros.transstatus !== "Assigned" &&
              pros.transstatus !== "Reject"
                ? "Please wait for " + pros.source_name + " to issue DC"
                : ""}
            </p>

            <p style={{ color: "red" }}>
              {pros.status === "1" && pros.transstatus === "Unloaded"
                ? "Waiting for " +
                  pros.destination_name +
                  " for Quantity Confirmation"
                : ""}
            </p>
          </Col>
          <Col span={24}>
            {pros.transstatus === "Assigned" ? (
              <Button
                onClick={() => TripActions(pros.transid, pros.tripid, "Reject")}
                type="danger"
                className="rejectbutton buttonFull"
              >
                {" "}
                Reject
              </Button>
            ) : (
              ""
            )}
          </Col>
          <p style={{ color: "green" }}>
            {pros.transstatus === "Trip End" ? "Trip Completed" : ""}
          </p>
        </Row>
      </div>
    </div>
  );
};

export default TripDetailCard;
